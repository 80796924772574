import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import Products from "../Products"
import Layout from "../Layout"
import Sidebar from "../Sidebar"
import { device } from "../../theme"
import { FilterContext } from "../../providers/FilterProvider"
import { initialState } from "../../reducer"
import Breadcrumbs from "../Breadcrumbs"

const Container = styled.div`
  display: flex;

  @media ${device.tablet} {
    flex-direction: column;
  }
`

function CategoryWrapper({ seoTitle, gender, category }) {
  return (
    <Layout title={seoTitle} isSticky>
      <Container>
        <Sidebar gender={gender} category={category} />
        <div style={{ width: '100%' }}>
          <Breadcrumbs gender={gender} category={category} />
          <Products gender={gender} category={category} />
        </div>
      </Container>
    </Layout>
  )
}

export default CategoryWrapper
