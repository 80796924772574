import React from "react"
import CategoryWrapper from "../../components/CategoryWrapper"

function MenTshirtsPage() {
  return (
    <CategoryWrapper
      seoTitle="T-shirts"
      gender="herre"
      category="tshirts"
    />
  )
}

export default MenTshirtsPage
